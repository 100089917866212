import {
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useUser } from "@auth0/nextjs-auth0/client";
import { FiArrowRight, FiLogIn, FiLogOut } from "react-icons/fi";

export default function Home() {
  const { user } = useUser();

  return (
    <Stack
      minH={"calc(100vh - 64px)"}
      direction={{ base: "column", md: "row" }}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <Center>
            <Heading fontSize={"3xl"}>Business Matchmaking ™</Heading>
          </Center>
          <Center>
            <Heading fontSize={"2xl"}>Access Control</Heading>
          </Center>
          {user ? (
            <>
              <Button
                colorScheme={"blue"}
                variant={"solid"}
                px={6}
                as="a"
                href="/access-control"
              >
                <HStack>
                  <Text>Continue</Text>
                  <Icon as={FiArrowRight} />
                </HStack>
              </Button>
              <Button
                colorScheme={"blue"}
                variant={"solid"}
                px={6}
                as="a"
                href="/api/auth/logout"
              >
                <HStack>
                  <Text>Sign Out</Text>
                  <Icon as={FiLogOut} />
                </HStack>
              </Button>
            </>
          ) : (
            <Button
              colorScheme={"blue"}
              variant={"solid"}
              px={6}
              as={"a"}
              href="/api/auth/login"
            >
              <HStack>
                <Text>Sign In</Text>
                <Icon as={FiLogIn} />
              </HStack>
            </Button>
          )}
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={"Login Image"} objectFit={"cover"} src={"access.jpg"} />
      </Flex>
    </Stack>
  );
}
